<template>
  <div>
    <div class="footer">
      <div class="left">
        <a-button v-hasPermi="['monitor_createorder_button']" v-if="!vehicleItem.online" @click="handleCreateOrder">生成工单</a-button>
        <a-button :loading="estopLoading" v-else type="danger" size="small" @click="handleEstop">刹停</a-button>
        <a-button v-if="vehicleItem.warnStatus === 4 || vehicleItem.warnStatus === 6" :loading="loading" size="small" @click="handleStopTask">终止任务</a-button>
        <a-button v-if="vehicleItem.warnStatus === 6" :loading="loading" size="small" @click="handleResumeTask">继续任务</a-button>
        <a-button v-if="vehicleItem.warnStatus !== 0 && vehicleItem.managedRequest && vehicleItem.managedRequest.orderId" size="small" @click="handleUpdateOrder">回填工单</a-button>
        <a-button :loading="hostLoading" v-if="vehicleItem.managedRequest && vehicleItem.managedRequest.managedRequestStatus === 1" size="small" @click="handleQuitHost">退出托管</a-button>
      </div>
      <a-button v-hasPermi="['monitor_control_button']" type="primary" size="small" @click="handleRemoteControl">遥控</a-button>
    </div>
    <work-order ref="orderRef" />
  </div>
</template>
<script>
import { stopVehilce, resumeVehicle, operationAck } from '@/api/iot/vehicleTaskRecord'
import { sendManagedRequest, sendEstop } from '@/api/vehicle/cmd'
import WorkOrder from '../WorkOrder'
import TaskBase from '../../../VehicleTask/TaskBase.js'
export default {
  mixins: [TaskBase],
  props: {
    vehicleItem: {
      type: Object,
      default: () => {}
    }
  },
  components: { WorkOrder },
  data() {
    return {
      loading: false,
      hostLoading: false,
      estopLoading: false
    }
  },
  methods: {
    handleRemoteControl() {
      this.$emit('onVehicleMonitorEvent', 'RemoteControlVehicle', this.vehicleItem.vehicleId)
    },
    handleStopTask() {
      this.$loading.show({
        msg: '正在停止任务'
      })
      const formData = new FormData()
      formData.append('vehicleId', this.vehicleItem.vehicleId)
      // this.loading = true
      stopVehilce(formData)
        .then((response) => {
          // 获取执行任务状态
          // this.loading = false
          // this.loading = false
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleItem.vehicleId
          }
          this.getTaskAck(operationAck, params, 'resume')
          this.$loading.hide()
        })
        .catch((e) => {
          // this.loading = false
          this.$loading.hide()
        })
    },
    handleResumeTask() {
      this.$loading.show({
        msg: '正在继续任务'
      })
      const formData = new FormData()
      formData.append('vehicleId', this.vehicleItem.vehicleId)
      // this.loading = true
      resumeVehicle(formData)
        .then((response) => {
          // 获取执行任务状态
          // this.loading = false
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
           const params = {
            vehicleId: this.vehicleItem.vehicleId
          }
          this.getTaskAck(operationAck, params, 'resume')
          this.$loading.hide()
        })
        .catch((e) => {
          // this.loading = false
          this.$loading.hide()
        })
    },
    handleCreateOrder() {
      this.$refs.orderRef.handleAdd(this.vehicleItem)
    },
    handleUpdateOrder() {
      console.log('handleUpdateOrder')
      if (this.vehicleItem.managedRequest && this.vehicleItem.managedRequest.orderId) {
        this.$refs.orderRef.handleUpdate(this.vehicleItem.managedRequest.orderId)
      }
    },
    handleQuitHost() {
      this.hostLoading = true
      const queryParam = {
        vehicleId: this.vehicleItem.vehicleId
      }
      sendManagedRequest(queryParam)
        .then((response) => {
          // 获取执行任务状态
          this.hostLoading = false
        })
        .catch((e) => {
          this.hostLoading = false
        })
    },
    handleEstop() {
      this.estopLoading = true
      const queryParam = {
        vehicleId: this.vehicleItem.vehicleId
      }
      sendEstop(queryParam)
        .then((response) => {
          // 获取执行任务状态
          this.estopLoading = false
        })
        .catch((e) => {
          this.estopLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  margin-bottom: 5px;
  .left {
    padding-left: 3px;
  }
  button {
    margin-right: 5px;
  }
}
</style>
