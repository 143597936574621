import request from '@/utils/request'

// 中控退出托管
export function sendManagedRequest(query) {
  return request({
    url: '/vehicle/cmd/command/managedRequest',
    method: 'get',
    params: query
  })
}

// 中控刹停
export function sendEstop(query) {
  return request({
    url: '/vehicle/cmd/command/estop',
    method: 'get',
    params: query
  })
}
