var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              !_vm.vehicleItem.online
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["monitor_createorder_button"],
                          expression: "['monitor_createorder_button']",
                        },
                      ],
                      on: { click: _vm.handleCreateOrder },
                    },
                    [_vm._v("生成工单")]
                  )
                : _c(
                    "a-button",
                    {
                      attrs: {
                        loading: _vm.estopLoading,
                        type: "danger",
                        size: "small",
                      },
                      on: { click: _vm.handleEstop },
                    },
                    [_vm._v("刹停")]
                  ),
              _vm.vehicleItem.warnStatus === 4 ||
              _vm.vehicleItem.warnStatus === 6
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading, size: "small" },
                      on: { click: _vm.handleStopTask },
                    },
                    [_vm._v("终止任务")]
                  )
                : _vm._e(),
              _vm.vehicleItem.warnStatus === 6
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading, size: "small" },
                      on: { click: _vm.handleResumeTask },
                    },
                    [_vm._v("继续任务")]
                  )
                : _vm._e(),
              _vm.vehicleItem.warnStatus !== 0 &&
              _vm.vehicleItem.managedRequest &&
              _vm.vehicleItem.managedRequest.orderId
                ? _c(
                    "a-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleUpdateOrder },
                    },
                    [_vm._v("回填工单")]
                  )
                : _vm._e(),
              _vm.vehicleItem.managedRequest &&
              _vm.vehicleItem.managedRequest.managedRequestStatus === 1
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.hostLoading, size: "small" },
                      on: { click: _vm.handleQuitHost },
                    },
                    [_vm._v("退出托管")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["monitor_control_button"],
                  expression: "['monitor_control_button']",
                },
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleRemoteControl },
            },
            [_vm._v("遥控")]
          ),
        ],
        1
      ),
      _c("work-order", { ref: "orderRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }